exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-arrow-icon-tsx": () => import("./../../../src/pages/ArrowIcon.tsx" /* webpackChunkName: "component---src-pages-arrow-icon-tsx" */),
  "component---src-pages-box-icon-tsx": () => import("./../../../src/pages/BoxIcon.tsx" /* webpackChunkName: "component---src-pages-box-icon-tsx" */),
  "component---src-pages-check-icon-tsx": () => import("./../../../src/pages/CheckIcon.tsx" /* webpackChunkName: "component---src-pages-check-icon-tsx" */),
  "component---src-pages-cpu-icon-tsx": () => import("./../../../src/pages/CpuIcon.tsx" /* webpackChunkName: "component---src-pages-cpu-icon-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sim-icon-tsx": () => import("./../../../src/pages/SimIcon.tsx" /* webpackChunkName: "component---src-pages-sim-icon-tsx" */),
  "component---src-pages-usb-icon-tsx": () => import("./../../../src/pages/UsbIcon.tsx" /* webpackChunkName: "component---src-pages-usb-icon-tsx" */)
}

